const overrides = (theme: any) => ({
  MuiTypography: {
    defaultComponent: 'p',
    defaultProps: {
      component: 'p',
    },
  },
  MuiGrid: {
    styleOverrides: {
      '& item': {
        padding: '12px',
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        display: 'block',
        height: 53,
        [theme.breakpoints.up('md')]: {
          width: 520,
        },
      },
      anchorOriginTopCenter: {
        height: 53,
        [theme.breakpoints.up('xs')]: {
          top: 80,
          left: '50%',
          right: 'auto',
          transform: 'translateX(-50%)',
        },
        [theme.breakpoints.up('md')]: {
          top: 24,
          left: '50%',
          right: 'auto',
          transform: 'translateX(-50%)',
        },
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: {
        backgroundColor: theme.colors.darkestGray,
        boxShadow:
          '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        fontSize: 16,
      },
      action: {
        fontSize: 16,
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          fontSize: 16,
          backgroundColor: 'red',
        },
        '#root': {
          height: '100%',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '16px',
        color: theme.colors.mediumGreen,
        textDecoration: 'none',
        '&:hover': {
          color: theme.colors.mediumDarkGreen,
        },
        '&:focus': {
          color: theme.colors.mediumDarkGreen,
        },
        '&$disabled': {
          color: theme.colors.tintedGreen,
        },
      },
    },
    underlineHover: {
      textDecoration: 'none',
      '&:hover': {
        color: theme.colors.mediumGreen,
        textDecoration: 'none',
      },
      '&:focus': {
        color: theme.colors.mediumGreen,
        textDecoration: 'none',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        padding: 24,
        backgroundColor: theme.colors.white,
        boxShadow: '0px 6px 12px rgb(27 27 28 / 15%)',
        '&:hover': {
          boxShadow: '0px 8px 24px rgba(27, 27, 28, 0.15)',
        },
        borderRadius: 16,
        boxSizing: 'border-box',
        border: `1px solid ${theme.colors.gainsboro}`,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow: 'none',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 0,
        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: 0,
        '&:first-child': {
          marginLeft: 0,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        maxHeight: 42,
        width: '100%',
        borderRadius: 8,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        fontFamily: 'Aeonik',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '18px',
        textTransform: 'none',
        letterSpacing: '0.5px',
        textAlign: 'center',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      label: {
        transition: 'color 250ms',
      },
      text: {
        padding: '12px 16px',
        color: theme.colors.mediumGreen,
        '&:hover': {
          backgroundColor: theme.colors.white,
        },
      },
      outlined: {
        border: `2px solid ${theme.colors.mediumGreen}`,
        color: theme.colors.mediumGreen,
        '&:hover': {
          background: theme.colors.white,
          border: `2px solid ${theme.colors.mediumDarkGreen}`,
          '& path': {
            fill: theme.colors.mediumDarkGreen,
          },
        },
        '&:active': {
          background: theme.colors.white,
          border: `2px solid ${theme.colors.bush}`,
          '& path': {
            fill: theme.colors.bush,
          },
        },
        '&:disabled': {
          border: `2px solid ${theme.colors.tintedGreen}`,
          color: theme.colors.tintedGreen,
        },
      },
      containedPrimary: {
        backgroundColor: theme.colors.mediumGreen,
        '&:hover': {
          backgroundColor: theme.colors.mediumDarkGreen,
        },
        '&$disabled': {
          color: theme.colors.white,
          backgroundColor: theme.colors.tintedGreen,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '21px',
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(68, 68, 68, 0.4)',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        padding: theme.spacing(3),
        boxShadow: '0px 16px 48px rgba(205, 205, 205, 0.5)',
      },
      paperWidthSm: {
        maxWidth: 449,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: 0,
        marginBottom: theme.spacing(2),
        width: '95%',
        '& h2': {
          fontFamily: 'DM Serif Display',
          fontSize: 25,
          lineHeight: 1.28,
          fontStyle: 'normal',
          fontWeight: 'normal',
          color: theme.colors.darkestGray,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 0,
        marginBottom: theme.spacing(4),
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        color: theme.colors.darkestGray,
        marginBottom: 0,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      spacing: {
        padding: 0,
      },
      root: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        width: 250,
        boxShadow:
          '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)',
        borderRadius: 16,
      },
    },
  },
  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: '12px 24px 12px 12px',
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiTabs: {
    styleOverrides: {
      fixed: {
        borderBottom: theme.colors.lightGray,
        borderBottomStyle: 'solid',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textAlign: 'left',
        textTransform: 'none',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        width: '100%',
        background: theme.colors.white,
        borderRadius: '16px 0px 0px 16px',
        [theme.breakpoints.up('sm')]: {
          width: '657px',
        },
        [theme.breakpoints.down(657)]: {
          borderRadius: 0,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 14,
        textAlign: 'center',
        fontWeight: 'bold',
        borderRadius: 4,
        padding: '7px 0',
        color: theme.colors.white,
        backgroundColor: theme.colors.darkestGray,
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: {
        color: theme.colors.mediumGreen,
      },
      li: {
        fontWeight: 'normal',
        '&:last-child': {
          fontWeight: 'normal',
        },
      },
    },
  },
});

export default overrides;
